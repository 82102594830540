@charset "UTF-8";
.prices {
  background-image: url("../../../assets/bg/prices-bg.jpg");
  width: 100vw;
  padding: 0 calc((100vw - 500px) / 2);
  padding-bottom: 100px; }
  .prices h1 {
    padding: 105px 0 80px 0; }
  .prices .price-list .item-row {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .prices .price-list .item-row:not(:last-child) {
      margin-bottom: 50px; }
    .prices .price-list .item-row .icon-wrap {
      flex: 0 0 35px;
      margin-right: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 35px;
      height: 35px; }
      .prices .price-list .item-row .icon-wrap .icon {
        position: absolute;
        height: 100%;
        width: auto; }
      .prices .price-list .item-row .icon-wrap.combined > .icon {
        position: absolute;
        height: 20.58824px; }
      .prices .price-list .item-row .icon-wrap.combined .icon:first-child {
        top: 0;
        left: 0; }
      .prices .price-list .item-row .icon-wrap.combined .icon:last-child {
        right: 0;
        bottom: 0; }
    .prices .price-list .item-row .name {
      flex: 1 1 auto; }
    .prices .price-list .item-row .price {
      flex: 0 0 auto; }
      .prices .price-list .item-row .price:after {
        content: '₴';
        margin-left: 2px; }
