@charset "UTF-8";
.prices-m {
  background-image: url("../../../assets/bg/prices-bg.jpg");
  padding-bottom: 100px; }
  .prices-m h1 {
    padding: 50px 0 100px 0;
    letter-spacing: 9px; }
  .prices-m .price-list {
    width: 90%;
    margin: 0 auto; }
    .prices-m .price-list .item-row {
      padding: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      letter-spacing: 3px; }
      .prices-m .price-list .item-row .name {
        flex: 1 1 auto;
        color: white; }
      .prices-m .price-list .item-row .price {
        color: white;
        flex: 0 0 auto; }
        .prices-m .price-list .item-row .price:after {
          content: '₴'; }
