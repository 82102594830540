@import '../../../styles/variables';

.main-nav {
  width: 100%;
  display: flex;
  justify-content: space-around;
  * {
    cursor: pointer;
    padding: $gap-m + 4px 0 $gap-m 0;
    width: calc(100% / 4 - #{$gap-m*2});
    display: flex;
    align-items: center;
    justify-content: center;
    color: $accent;
    text-decoration: none;
    border: 3px solid $accent;
    transition: border .2s linear, background-color .2s linear;
    &:hover {
      background-color: white;
      border: 3px solid transparent;
      transition: border .2s linear, background-color .2s linear;
    }
  }
}
