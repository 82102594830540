@import '../../../styles/variables';

.appointment {
  background-image: url('../../../assets/bg/appointment-bg.jpg');
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .appointment-button {
    width: 324px;
    height: 82px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-top: 4px;
    justify-content: center;
    color: white;
    font-size: $ui-heading;
    border: 3px solid white;
    transition: border .2s linear, background-color .2s linear, color .2s linear;
    &:hover {
      color: $accent;
      background-color: white;
      border: 3px solid transparent;
      transition: border .2s linear, background-color .2s linear, color .2s linear;
    }
  }
}
