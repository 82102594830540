@import '../../../styles/variables';

.nav-open-button-m {
  position: absolute;
  padding: $gap-m;
  right: 0;
  top: 0;
  width: $icon-size / 2;
  height: $icon-size / 2;
  box-sizing: content-box;
  z-index: 99;
}

.main-nav-m {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.93);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 1;
  pointer-events: auto;
  transition: opacity .2s ease-in-out;
  &.hidden {
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s ease-in-out;
  }

  * {
    padding: $gap-m + $gap-s + 4px 0 $gap-m + $gap-s 0;
    cursor: pointer;
    width: 80%;
    letter-spacing: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $accent;
    text-decoration: none;
    background-color: transparent;
    border: 3px solid $accent;
    transition: border .2s linear, background-color .2s linear;
    &:not(:last-child) { margin-bottom: $gap-l; }
    &:hover {
      background-color: white;
      border: 3px solid transparent;
      transition: border .2s linear, background-color .2s linear;
    }
  }
  z-index: 98;
}
