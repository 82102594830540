@import './styles/variables';
@import './styles/mixins';

* {
  color: $accent;
  box-sizing: border-box;
}

body {
  text-transform: uppercase;
}

.screen {
  @include set-section-content-width();
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

a {
  text-decoration: none;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .screen {
    @include set-section-content-width(800px);
  }
}
