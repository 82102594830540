@import '../../../styles/variables';

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-image: url('../../../assets/bg/header-bg.jpg');
  height: 100vh;
  color: $accent;
  .contacts {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    .address {
      flex: 1 1 calc(100% / 3);
      letter-spacing: 4px;
    }

    .barbershop {
      text-align: center;
      flex: 1 1 calc(100% / 3);
      letter-spacing: 16px;
      img {
        width: 100px;
        margin-bottom: 32px;
      }
    }

    .sn-wh-wrap {
      flex: 1 1 calc(100% / 3);
      display: flex;
      justify-content: flex-end;
      letter-spacing: 4px;
      .social-networks {
        margin-left: $gap-m;
        .sn-icon {
          margin-left: $gap-m;
          height: $icon-size;
          cursor: pointer;
        }
      }
    }
  }

  .logo {
    width: 50%;
    max-height: 50vh;
    //max-height: 100%;
  }
}


