
.location {
  background-image: url('../../../assets/bg/location-bg.jpg');
  padding: 0;
  h1 { padding: 75px 0 70px 0; }
  .map-wrap {
    width: 100%;
    height: 600px;
    position: relative;
  }
}

