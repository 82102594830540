@import '../../../styles/variables';

.barbers-m {
  h1 {
    margin-bottom: $gap-m;
    letter-spacing: 8px;
  }

  .barbers-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .barber {
    width: 100vw;
    background-size: cover;
    padding: $gap-l;
    display: flex;
    align-items: center;
    flex-direction: column;
    .name {
      text-align: center;
      color: $text-black;
      letter-spacing: 5px;
    }
    .picture {
      max-width: 90%;
    }
    .instagram {
      width: $icon-size;
      height: $icon-size;
      margin-top: $gap-m;
    }
    &:not(:last-child) {
      border-bottom: 3px solid white;
    }
    @for $i from 0 to 10 { // 10 - max number of barbers
      &:nth-child( #{$i + 1} ) {
        background-image: url('../../../assets/bg/mobile/barber_bg-' + ($i % 3 + 1) + '.jpg');
      }
    }
  }

  .divider {
    width: 40%;
    height: 2px;
    background-color: $accent;
    margin: $gap-m * 3 0 $gap-l 0;
  }
}
