@import '../../../styles/variables';

.footer {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo {
    width: 170px;
    margin: $gap-l*2 0 $gap-m*3 0;
  }

  .content-wrap {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: $gap-l;
    * {
      text-align: center;
      color: white;
    }
    .sn-wrap img {
      width: $icon-size;
      height: $icon-size;
      margin: 0 $gap-s;
    }
  }

  .copywrite {
    color: white;
    margin: $gap-l*2 0;
    font-size: $ui-copywrite;
  }

}