@import '../../../styles/variables';

.header-m {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: url('../../../assets/bg/mobile/header_bg.jpg');
  height: 100vh;
  min-height: 520px;
  padding: $gap-l 0;
  text-align: center;
  letter-spacing: 3px;

  .barbershop {
    text-align: center;
    letter-spacing: 10px;
    color: $accent;
    margin-bottom: $gap-m;
    img {
      width: 13vw;
      margin-bottom: $gap-m;
    }
  }

  .address {
    flex: 0 0 auto;
    color: white;
    margin-bottom: $gap-m;
  }

  .working-hours {
    flex: 0 0 auto;
    color: white;
  }

  .logo {
    flex: 1 1 auto;
    width: 80%;
    margin-bottom: $gap-l;
  }

  .appointment-button {
    flex: 0 0 82px;
    width: 80%;
    display: flex;
    align-items: center;
    padding-top: 4px;
    justify-content: center;
    color: white;
    font-size: $ui-heading;
    border: 3px solid white;
    transition: border .2s linear, background-color .2s linear, color .2s linear;
    letter-spacing: 3px;
    &:hover {
      color: $accent;
      background-color: white;
      border: 3px solid transparent;
      transition: border .2s linear, background-color .2s linear, color .2s linear;
    }
  }
}


