@import '../../../styles/variables';

.location-m {
  background-image: url('../../../assets/bg/location-bg.jpg');
  padding: 0;
  h1 {
    padding: $gap-m + 4px 0 $gap-m 0;
    letter-spacing: 8px;
  }
  .map-wrap {
    width: 100%;
    height: 400px;
    position: relative;
  }
}

