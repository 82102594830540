// Fonts Path
$font-path: 'assets/fonts/';

// Colors
$accent: #9c825c;
$text-black: #010101;

// Offsets
$gap-l: 35px;
$gap-m: 15px;
$gap-s: 10px;

//$side-offset: calc((100vw - 1024px) / 2);

// Sizes
$menu-height: 70px;
$icon-size: 35px;
$service-icon-size: 60px;

// Fonts
$ui-copywrite: 0.875rem;
$ui-content: 1.125rem;
//$ui-content: 1.5625rem;
$ui-heading: 1.75rem;
//$ui-heading: 2.1875rem;
