@import './styles/mixins';
@import './styles/variables';

@include font-face('Bebas Neue Bold', $font-path+'bebasneuebold', 800, bold, woff woff2 ttf);
@include font-face('Bebas Neue Book', $font-path+'bebasneuebook', 500, normal, woff woff2 ttf);
@include font-face('Rex Bold', $font-path+'rexbold', 800, bold, woff woff2 ttf);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Bebas Neue Bold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  background-color: black;
  font-size: $ui-content;
  -webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Rex Bold', sans-serif;
}

h1 {
  font-size: $ui-heading;
  text-align: center;
  color: $accent;
}

#root {
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
