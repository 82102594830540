@import '../../../styles/variables';

.footer-m {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  & * {
    color: white;
    margin-top: $gap-m;
  }

  .sn-wrap {
    margin-bottom: $gap-m;
    img {
      width: $icon-size;
      height: $icon-size;
      margin: 0 $gap-s;
    }
  }
}