@import '../../../styles/variables';

.barbers {
  background-image: url('../../../assets/bg/barbers-bg.jpg');
  h1 {
     padding-top: 105px;
   }
  padding-bottom: 80px;
  .barbers-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .barber {
    flex: 0 0 35%;
    margin: 80px auto 0 auto;
    //width: 32%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .name {
      text-align: center;
      color: $text-black;
      letter-spacing: 5px;
    }
    .picture {
      max-width: 90%;
    }
    .instagram {
      width: $icon-size;
      height: $icon-size;
      margin-top: $gap-m;
    }
  }

  .divider {
    width: 40%;
    height: 2px;
    background-color: $accent;
    margin: $gap-m * 3 0 $gap-l 0;
  }
}


